import { SETTING_TYPE } from 'constants/cookie';

export interface ICookieParam {
  name: string;
  description: string;
  type: string;
  isDisabled: boolean;
}

export const settings: ICookieParam[] = [
  {
    name: 'Essential',
    description:
      'These cookies are necessary for the proper operation of sites and their individual functions.\n\nExample: Authorization cookies',
    type: SETTING_TYPE.SYSTEM,
    isDisabled: true,
  },
  {
    name: 'Security',
    description:
      'These cookies help us to maintain a secure environment in order to protect both your and our interests and to identify potential threats.',
    type: SETTING_TYPE.SECURITY,
    isDisabled: false,
  },
  {
    name: 'Performance',
    description:
      'These cookies increase the usability of sites and provide them with additional functions.\n\nExample: Cookies that store search results, language settings, or font size.',
    type: SETTING_TYPE.PERFORMANCE,
    isDisabled: false,
  },
  {
    name: 'Marketing',
    description:
      'These cookies allow us to show you more personalized content, measure our services, analyze web traffic, and improve your browsing experience.',
    type: SETTING_TYPE.MARKETING,
    isDisabled: false,
  },
];
