import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import CookiePageWrapper from 'components/blocks/CookiePage/CookiePageWrapper/CookiePageWrapper';
import CookieSettings from 'components/blocks/CookiePage/CookieSettings/CookieSettings';

const description = `
  This page allows you to change the cookie settings for this site. After changing the settings, please click the save settings button.


  To find out more, please check our [List of Cookies](/cookies-list "List of cookies")
`;

const IndexPage: FC<IPageProps> = () => (
  <>
    <Helmet>
      <title>WayRay | Cookie settings</title>
      <meta property="og:title" content="WayRay | Cookie settings" />
    </Helmet>
    <CookiePageWrapper title="Cookie settings" description={description}>
      <CookieSettings />
    </CookiePageWrapper>
  </>
);

export default IndexPage;
