import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';
import { MiddleText, Button as UIButton } from 'UI';

export const Container = styled.div``;

export const Title = styled(MiddleText)`
  grid-column: 3/5;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Text = styled(Markdown)`
  grid-column: 5/9;
  display: grid;
  grid-auto-flow: row;
  gap: 1em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 3/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Option = styled.div`
  grid-column: 5/9;
  margin-bottom: 5em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 3/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
    margin-bottom: 2em;
  }
`;

export const Label = styled.label`
  display: grid;
  align-content: center;
  grid-auto-flow: column;
  justify-content: start;
  cursor: pointer;

  ::before {
    content: '';
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    border-radius: 0.2em;
    margin-right: 1em;
    border: 1px solid var(--gray-4);
    background-size: 0.8em;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const Checkbox = styled.input`
  display: none;

  :disabled {
    display: none;

    + ${Label} {
      color: var(--gray);
      ::before {
        content: none;
      }
    }
  }

  :checked {
    + ${Label} {
      ::before {
        border: 1px solid var(--red);
        background-image: url('/svg/checkmark.svg');
      }
    }
  }
`;

export const Button = styled(UIButton)`
  grid-column: 3/5;

  background: var(--red);
  color: var(--white);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/3;
  }
`;
