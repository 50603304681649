import React, {
  Fragment, useState, useEffect, FC,
} from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';

import { SETTING_TYPE, COOKIE_AGREEMENT } from 'constants/cookie';

import { setCookieAgreement } from 'store/actions';

import { settings } from './settings';

import {
  Title, Text, Option, Checkbox, Label, Button,
} from './styled';

const CookieSettings: FC = () => {
  const dispatch = useDispatch();
  const [cookieSettings, setCookieSettings] = useState({
    [SETTING_TYPE.SYSTEM]: true,
    [SETTING_TYPE.SECURITY]: false,
    [SETTING_TYPE.PERFORMANCE]: false,
    [SETTING_TYPE.MARKETING]: false,
  });

  const handleSaveSettings = async () => {
    localStorage.setItem('cookie-settings', JSON.stringify(cookieSettings));
    localStorage.setItem(COOKIE_AGREEMENT, 'true');
    dispatch(setCookieAgreement(true));

    await navigate(
      '/',
      {
        replace: true,
      },
    );
  };

  useEffect(() => {
    const settingsLocalStorage = localStorage.getItem('cookie-settings');

    if (settingsLocalStorage) {
      setCookieSettings(JSON.parse(settingsLocalStorage) as { string: boolean });
    }
  }, []);

  const handleChange = (type: string, value: boolean) => {
    setCookieSettings({
      ...cookieSettings,
      [type]: value,
    });
  };

  return (
    <>
      {settings.map(({
        name, description, isDisabled, type,
      }) => (
        <Fragment key={`cookie-setting_${name}`}>
          <Title>{name}</Title>

          <Text>{description}</Text>

          <Option>
            <Checkbox
              id={`cookie-name_${name}`}
              type="checkbox"
              checked={cookieSettings[type]}
              onChange={(event) => handleChange(type, event.target.checked)}
              disabled={isDisabled}
            />
            <Label htmlFor={`cookie-name_${name}`}>{isDisabled ? 'Enabled' : 'Disable'}</Label>
          </Option>
        </Fragment>
      ))}

      <Button onClick={handleSaveSettings}>Save settings</Button>
    </>
  );
};

export default CookieSettings;
